import React, { useState } from 'react'
import { contactMail } from '../../Services/Services';
import Swal from 'sweetalert2';
import logo from "../../Images/logo_white_web.png"

export default function ContactUs({ data, setLoder, webpreview }) {

    const [fdata, setFdata] = useState({ 'fname': '', 'lname': '', 'email': '', 'message': '' });
    const [error, setError] = useState({ 'fname': '', 'lname': '', 'email': '', 'message': '' });

    const changeHandler = (e) => {
        const { name, value } = e.target;
        setFdata((pre) => ({ ...pre, [name]: value }));
        setError((pre) => ({ ...pre, [name]: '' }));
    }

    const issubmit = async (e) => {
        e.preventDefault();
        let isValid = 1;

        if (!fdata.fname) { setError((pre) => ({ ...pre, 'fname': 'Please enter your first name' })); isValid = 2; };
        if (!fdata.lname) { setError((pre) => ({ ...pre, 'lname': 'Please enter your last name' })); isValid = 2; };
        if (!fdata.email) { setError((pre) => ({ ...pre, 'email': 'Please enter your valid email address' })); isValid = 2; };
        if (!fdata.message) { setError((pre) => ({ ...pre, 'message': 'Please enter your comments' })); isValid = 2; };
        if (isValid === 1) {
            setLoder(true);
            const finalData = {
                "fname": fdata.fname,
                "lname": fdata.lname,
                "email": fdata.email,
                "message": fdata.message
            }
            const resp = await contactMail(finalData);
            if (resp) {
                setLoder(false);
                Swal.fire({
                    html: `<img src=${logo} class="img-fluid d-block mx-auto mt-5" />
                <h4 class="title text-uppercase text-center mt-4 textWhite">
                SUCCESS
                </h4>
                <p class="text-center my-4 textcolor">
                <h4 class="textWhite">
                ${resp.data.message} </h4></p> `,
                    showClass: {
                        popup: 'custom-dialog-container',
                    },
                    customClass: {
                        confirmButton: 'custom-button custom-button-size modalbtn',
                    },
                });
                e.target.reset();
                setLoder(false);
            }
            setLoder(false);
        }
        setLoder(false);
    }


    const checkHandler = () => {

    }
    return (
        <>
            {/* Contact Us */}
            <section id="WarriorMobileApp">
                <div className="container">
                    <div className='row'>
                        <div className='row section-title text-center'>
                            <h2 className="dark-color pb-2 pr-3" style={{ color: `${webpreview && webpreview.Header_Fonts ? webpreview.Header_Fonts : ""}` }}> Client Mobile App </h2>
                            <small className='myline'></small>
                        </div>
                        <div className="text-left">
                            <p className="pb-3 mb-3 font-16 line-height25 border-bottom text-center">
                                The Warrior client mobile app is available for Apple or Android phones. For the best user experience, download the free app using the link below, sign up and check-in for classes, book an appointment, or purchase a pass, product, or service.
                            </p>
                            <p className="brand-color1 font-w-900 font-25" style={{ color: `${webpreview && webpreview.Header_Fonts ? webpreview.Header_Fonts : ""}` }} > Get the app </p>
                            <ul className='mt-3'>
                                <li>
                                    <a href="https://play.google.com/store/apps/details?id=com.warriorsms.user&hl=en-GB&ah=rkMVvfpr-j_NuZhcdKB1TOMunbg" target='blank'> <img src="clientassets/img/google_play_btn.png" /></a>
                                    <a href="https://apps.apple.com/us/app/warrior-sms-client/id1532481648" target='blank'> <img src="clientassets/img/app_store_btn.png" /></a>
                                </li>
                                {/* <li> </li> */}
                            </ul>
                        </div>
                    </div>
                    <div className="row" id='contact-us'>
                        <div className="col-xs-12">
                            <div className="section-title text-center">
                                <h2 style={{ color: `${webpreview && webpreview.Header_Fonts ? webpreview.Header_Fonts : ""}` }}>Contact us</h2>
                                <small className='myline'></small>
                                <p>Get in touch</p>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xs-12 col-sm-7 col-md-7 col-lg-8">
                            <form onSubmit={issubmit}>
                                <div className="row">
                                    <div className="col-sm-12 col-sm-6">
                                        <div className="form-group">
                                            <input
                                                placeholder="First Name"
                                                id="fname"
                                                className="form-control"
                                                name="fname"
                                                onChange={changeHandler}
                                                type="text"
                                            />
                                            <small className='error'>{error.fname}</small>
                                        </div>
                                    </div>
                                    <div className="col-sm-12 col-sm-6">
                                        <div className="form-group">
                                            <input
                                                placeholder="Last Name"
                                                id="lname"
                                                className="form-control"
                                                name="lname"
                                                onChange={changeHandler}
                                                type="text"
                                            />
                                            <small className='error'>{error.lname}</small>
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <input
                                        placeholder="Email Address"
                                        id="email"
                                        className="form-control"
                                        name="email"
                                        onChange={changeHandler}
                                        type="email"
                                    />
                                    <small className='error'>{error.email}</small>
                                </div>
                                <div className="form-group">
                                    <textarea
                                        placeholder="Your Comments"
                                        id="message"
                                        name='message'
                                        onChange={changeHandler}
                                        cols={20}
                                        rows={6}
                                        className="form-control"
                                    />
                                    <small className='error'>{error.message}</small>
                                </div>
                                <div className="form-group">
                                    <h5>
                                        Click the box for each type of communication you want to receive.
                                        {/* Check box for type of communication: type an X after each type of communication you want listed on the site.  */}
                                    </h5>
                                </div>
                                <div className='form-group'>
                                    <div className='col-md-4 mb-3'>
                                        <div className='form-check'>
                                            <input
                                                className="form-check-input"
                                                type="checkbox"
                                                name='Newsletter'
                                                onChange={checkHandler}
                                                id="Newsletter"
                                            />
                                            <label className="form-label" htmlFor="Newsletter">
                                                Blog / Newsletter
                                            </label>
                                        </div>
                                    </div>
                                    <div className='col-md-4 mb-3'>
                                        <div className='form-check'>
                                            <input
                                                className="form-check-input"
                                                type="checkbox"
                                                name='Marketing'
                                                onChange={checkHandler}
                                                id="Marketing"
                                            />
                                            <label className="form-label" htmlFor="Marketing">
                                                Marketing
                                            </label>
                                        </div>
                                    </div>
                                    <div className='col-md-4 mb-3'>
                                        <div className='form-check'>
                                            <input
                                                className="form-check-input"
                                                type="checkbox"
                                                name=' Studio_News'
                                                onChange={checkHandler}
                                                id=" Studio_News"
                                            />
                                            <label className="form-label" htmlFor=" Studio_News">
                                                Studio News
                                            </label>
                                        </div>
                                    </div>
                                </div>

                                <div className='row d-flex' style={{ justifyContent: "center" }}>
                                    <button
                                        className="btn btn-primary"
                                        type="submit"
                                        style={{ cursor: 'pointer', background: `${webpreview && webpreview.Borders_and_Buttons ? webpreview.Borders_and_Buttons : "#0d92d1"}`, color: `${webpreview && webpreview.button_text_color ? webpreview.button_text_color : "#fff"}` }}
                                    >submit
                                    </button>
                                </div>

                            </form>
                        </div>
                        <div className="col-xs-12 col-sm-5 col-md-5 col-lg-4">
                            <ul>
                                <li>
                                    <div className="contact-list">
                                        {/* <i className="fa fa-map-marker" /> */}
                                        <div className="contact-info d-flex">
                                            <img src='google_map_icon.png' style={{ maxWidth: "24px", maxHeight: '24px', marginRight: '10px' }} />
                                            <p >
                                                <span>
                                                    {data && data.location_url ?
                                                        <a href={data.location_url} target='blank' style={{ color: 'black', fontSize: '16px', fontFamily: `${webpreview && webpreview.font_family}` }}>
                                                            {data && data.bsaddress}
                                                        </a>
                                                        :
                                                        data && data.bsaddress
                                                    }
                                                </span>
                                                {/* 3481 Rabana Place, Beverly */}
                                                {/* <br /> Hills CA 2090 */}
                                            </p>
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <div className="contact-list">
                                        {/* <i className="fa fa-phone" /> */}
                                        <div className="contact-info d-flex">
                                            <img src='accept_call_icon.png' style={{ maxWidth: "24px", maxHeight: '24px', marginRight: '10px' }} />
                                            <p>
                                                <span>
                                                    <a href="#" style={{ color: 'black', fontSize: '16px', fontFamily: `${webpreview && webpreview.font_family}` }}>
                                                        {data && "" + data.bsphone}
                                                    </a>
                                                </span>
                                            </p>
                                        </div>
                                    </div>
                                </li>
                                {data && data.bsemail &&
                                    <li>
                                        <div className="contact-list">
                                            {/* <i className="fa fa-envelope" /> */}
                                            <div className="contact-info d-flex">
                                                <img src='gamil_icon.png' style={{ maxWidth: "24px", maxHeight: '24px', marginRight: '10px' }} />
                                                <p>
                                                    <a href={`mailto:${data.bsemail}`} target="_blank" style={{ color: 'black', fontSize: '16px', fontFamily: `${webpreview && webpreview.font_family}` }}>
                                                        {data.bsemail}
                                                    </a>
                                                </p>
                                            </div>
                                        </div>
                                    </li>
                                }
                                {/* Facebook */}
                                {data && data.facebook_id &&
                                    <li>
                                        <div className="contact-list">
                                            {/* <i className="fa fa-facebook" style={{ fontSize: '24px' }} /> */}
                                            <div className="contact-info d-flex">
                                                <img src='facebookicon.png' style={{ maxWidth: "23px", maxHeight: '23px', marginRight: '10px' }} />
                                                <p>
                                                    <a href={`https://www.facebook.com/${data.facebook_id}`} target="_blank" style={{ color: 'black', fontSize: '16px', fontFamily: `${webpreview && webpreview.font_family}` }}>
                                                        {data.facebook_id}
                                                    </a>
                                                </p>
                                            </div>
                                        </div>
                                    </li>
                                }
                                {/* Instagram FaceBook */}
                                {data && data.insta_id &&
                                    <li>
                                        <div className="contact-list">
                                            <div className="contact-info d-flex">
                                                <img src='instagramicon.png' style={{ maxWidth: "22px", maxHeight: '22px', marginRight: '10px' }} />
                                                <picture>
                                                    <a href={`https://www.instagram.com/${data.insta_id}`} target="_blank" style={{ color: 'black', fontSize: '16px', fontFamily: `${webpreview && webpreview.font_family}` }}>
                                                        {data.insta_id}
                                                    </a>
                                                </picture>
                                            </div>
                                        </div>
                                    </li>
                                }

                                {data && data.video_title &&
                                    <li>
                                        <div className="contact-list">
                                            <div className="contact-info d-flex">
                                                <img src='media.png' style={{ maxWidth: "22px", maxHeight: '22px', marginRight: '10px' }} />
                                                <picture>
                                                    <a href={data.video_channel_url} target="_blank" style={{ color: 'black', fontSize: '16px', fontFamily: `${webpreview && webpreview.font_family}` }}>
                                                        {data.video_title}
                                                    </a>
                                                </picture>
                                            </div>
                                        </div>
                                    </li>
                                }

                                {data && data.logo &&
                                    <li>
                                        <div className="contact-list">
                                            <div className="contact-info d-flex">
                                                <p>
                                                    <img src={data.logo} className='img-fluid' alt='' style={{ maxHeight: "50px", maxWidth: '250px' }} />
                                                </p>
                                            </div>
                                        </div>
                                    </li>

                                }

                            </ul>

                        </div>
                    </div>
                </div>
            </section>
            {/* End Contact Us */}
        </>
    )
}
