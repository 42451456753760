import React, { useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import VideoWithLoader from './VideoWithLoader';

export default function Gallery({ data, webpreview, position }) {
    const [showModal, setShowModal] = useState(false);
    const [modalContent, setModalContent] = useState(null);


    const handleShowModal = (content) => {
        setModalContent(content);
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
        setModalContent(null);
    };


    const hitUrlHandler = (url) => {
        window.open(url, '_blank');
    }

    return (
        <>
            {/* Gallery */}
            {position == 1 ?
                webpreview && webpreview.other_title_1 &&
                <section id="gallery1">
                    <div className="container">
                        <div className="row">
                            <div className="col-xs-12">
                                <div className="section-title text-center">
                                    <h2 style={{ fontFamily: webpreview?.other_title_1_font_family, color: webpreview?.other_title_1_font_color ? "#" + webpreview?.other_title_1_font_color : "black" }}> {webpreview && webpreview.other_title_1 ? webpreview.other_title_1 : "Gallery"}</h2>
                                    <p style={{ fontFamily: webpreview?.other_description_1_font_family, color: webpreview?.other_description_1_color_code ? "#" + webpreview?.other_description_1_color_code : "black" }}>  {webpreview && webpreview.other_description_1 ? webpreview.other_description_1 : "Invitamus me testatur sed"}</p>
                                </div>
                            </div>
                        </div>
                        <div className='row' style={{justifyContent:'center'}}>
                            {data.map((e, index) => (
                                e.data_type === 'image' ?
                                    <div className='col-md-4 p-3' style={{ cursor: 'pointer' }} key={index}>
                                        <div className="gallery-img">
                                            <img
                                                src={e.data_file}
                                                height="200"
                                                className="img-fluid img-size object-contain"
                                                alt={`Gallery item ${index + 1}`}
                                                onClick={() =>
                                                    handleShowModal(
                                                        <img
                                                            src={e.data_file}
                                                            className="img-fluid"
                                                            alt={`Gallery item ${index + 1}`}
                                                        />
                                                    )
                                                }
                                            />
                                        </div>
                                    </div>
                                    :
                                    <div className='col-md-4 p-3' key={index}>
                                        <div className="gallery-video">
                                            <VideoWithLoader src={e.data_file} type="video/mp4" />
                                        </div>
                                    </div>
                            ))}
                        </div>

                        {webpreview?.show_gallery_button_1 === "yes" &&

                            <div className='d-flex' style={{
                                justifyContent: 'center',
                                alignItems: 'center',
                                margin: '10px 0px'
                            }}>
                                <Button
                                variant='primary' 
                                onClick={() => {
                                    const url = webpreview?.other_url_1;
                                    let finalUrl = url;                                
                                    if (url) {
                                      if (!url.startsWith('https://') && !url.startsWith('http://')) {
                                        finalUrl = `http://${url}`; 
                                      }
                                    }
                                
                                    hitUrlHandler(finalUrl);
                                  }}
                                  >                              
                                {webpreview && webpreview.button_name1}
                                </Button>
                            </div>
                        }
                    </div>
                </section >

                : webpreview && webpreview.other_title_2 &&
                <section id="gallery2">
                    <div className="container">
                        <div className="row">
                            <div className="col-xs-12">
                                <div className="section-title text-center">
                                    <h2 style={{ fontFamily: webpreview?.other_title_2_font_family, color: webpreview?.other_title_2_font_color ? "#" + webpreview?.other_title_2_font_color : 'black' }}> {webpreview && webpreview.other_title_2 ? webpreview.other_title_2 : "Gallery"}</h2>
                                    <p style={{ fontFamily: webpreview?.other_description_2_font_family, color: webpreview?.other_description_2_font_color ? "#" + webpreview?.other_description_2_font_color : "black" }}>  {webpreview && webpreview.other_description_2 ? webpreview.other_description_2 : " "}</p>
                                </div>
                            </div>
                        </div>

                        <div className='row' style={{justifyContent:'center'}}>
                            {data.map((e, index) => (
                                e.data_type === 'image' ?
                                    <div className='col-md-4 p-3' style={{ cursor: 'pointer' }} key={index}>
                                        <div className="gallery-img">
                                            <img
                                                src={e.data_file}
                                                className="img-fluid img-size object-contain"
                                                alt={`Gallery item ${index + 1}`}
                                                onClick={() =>
                                                    handleShowModal(
                                                        <img
                                                            src={e.data_file}
                                                            className="img-fluid"
                                                            alt={`Gallery item ${index + 1}`}
                                                        />
                                                    )
                                                }
                                            />
                                        </div>
                                    </div>
                                    :
                                    <div className='col-md-4' key={index}>
                                        <div className="gallery-video">
                                            <VideoWithLoader src={e.data_file} type="video/mp4" />
                                        </div>
                                    </div>
                            ))}
                        </div>
                        {webpreview?.show_gallery_button_2 === "yes" &&
                            <div className='d-flex' style={{
                                justifyContent: 'center',
                                alignItems: 'center',
                                margin: '10px 0px'
                            }}>
                                <Button 
                                variant='primary' 
                                onClick={() => {
                                    const url = webpreview?.other_url_2;
                                    let finalUrl = url;                                
                                    if (url) {
                                      if (!url.startsWith('https://') && !url.startsWith('http://')) {
                                        finalUrl = `http://${url}`; 
                                      }
                                    }
                                
                                    hitUrlHandler(finalUrl);
                                  }}
                                >
                                {webpreview && webpreview.button_name2}
                                </Button>
                            </div>
                        }
                    </div>
                </section>

            }

            {/* Modal */}
            <Modal show={showModal} onHide={handleCloseModal} centered size="lg" animation={false}>
                <Modal.Header closeButton>
                    <Modal.Title>Gallery</Modal.Title>
                </Modal.Header>
                <Modal.Body className="text-center">{modalContent}</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseModal}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}
