import React, { useContext, useState } from 'react'
import { Link, animateScroll as scroll } from 'react-scroll';
import { MyContext } from '../App';
export default function Sidebar({ maindata, webpreview }) {
  const { fullInfo } = useContext(MyContext);
  const [togal, setTogal] = useState(false);

  const toggleMenu = () => {
    setTogal(!togal);
    if (!togal) {
      const element = document.querySelector('navsupport');
      if (element) {
        element.style.display = 'block';
      }
    }
  };


  const handleMouseEnter = (event) => {
    event.target.style.color = fullInfo && fullInfo.Header_Fonts; // Change color to red when hovered
    event.target.style.textDecoration = 'none';
    event.target.style.cursor = 'pointer';
  }

  const handleMouseLeave = (event) => {
    event.target.style.color = "#fff"; // Change color to red when hovered
    event.target.style.textDecoration = 'none';
  }



  return (
    <>
      <div className='navsupport' >
      </div>
      <aside className={`menu animate__animated ${togal ? 'animate__fadeInLeft' : ''}`} id="menu" onClick={toggleMenu} style={{ left: togal ? "0px" : "-220px" }}>
        <a
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          href="#" className="mobile-menu-opener">
          <i className="fa fa-bars" />
        </a>
        <nav className="navbar navbar-default navbar-static-top">

          {/* Title Banners  / Contact Form  */}
          <div id="navbar">
            <div className="row" style={{ textAlign: "center" }} id="brname">
              <span>
                <img src={maindata && maindata.logo ? maindata.logo : "img/logo.png"} style={{ maxWidth: '70px' }} alt="Logo" />
                <h5>{maindata && maindata.business_name}</h5>
              </span>
              <div className='col-12' style={{ textAlign: 'right', paddingRight: '48px', cursor: 'pointer' }} onClick={() => toggleMenu(false)} >
                <span style={{ textAlign: 'end', fontSize: '23px', border: '1px solid white', padding: '0px 12px' }}>X</span>
              </div>
            </div>
            <div style={{height:'68vh',overflow:"auto"}}>
              <ul className="nav navbar-nav navbar-left mt-4">
                <li >
                  <Link
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                    to="home" smooth={true} duration={500}>
                    Home
                  </Link>
                </li>
                <li>
                  <Link
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                    to="about-us" smooth={true} duration={500}>
                    {maindata?.about?.abtitle
                      ? maindata.about.abtitle.substring(0, 20) + (maindata.about.abtitle.length > 20 ? "..." : "")
                      : "About Us"}
                  </Link>
                </li>
                {webpreview && webpreview?.showOtherSection1 === "yes" &&
                  <li>
                    <Link
                      onMouseEnter={handleMouseEnter}
                      onMouseLeave={handleMouseLeave}
                      to="gallery1" smooth={true} duration={500}>
                      {webpreview?.other_title_1
                        ? webpreview?.other_title_1.substring(0, 20) + (webpreview?.other_title_1 > 20 ? "..." : "")
                        : "Gallery"}
                    </Link>
                  </li>
                }
                {maindata && maindata.showInstructor === "yes" &&
                  <li>
                    <Link
                      onMouseEnter={handleMouseEnter}
                      onMouseLeave={handleMouseLeave}
                      to="Instructors" smooth={true} duration={500}>
                      {webpreview?.inst_header
                        ? webpreview?.inst_header.substring(0, 20) + (webpreview?.inst_header > 20 ? "..." : "")
                        : "Instructors"}
                    </Link>
                  </li>
                }
                {maindata && maindata.showClass === "yes" &&
                  <>
                    <li>
                      <Link
                        onMouseEnter={handleMouseEnter}
                        onMouseLeave={handleMouseLeave}
                        to="Classes" smooth={true} duration={500}>
                        {maindata?.class_title
                          ? maindata?.class_title.substring(0, 20) + (maindata?.class_title > 20 ? "..." : "")
                          : "Classes"}
                      </Link>
                    </li>
                    <li>
                      <Link
                        onMouseEnter={handleMouseEnter}
                        onMouseLeave={handleMouseLeave}
                        to="Class_Schedule" smooth={true} duration={500}>
                        {maindata?.class_title
                          ? maindata?.class_title.substring(0, 20) + " " + (maindata?.class_title > 20 ? "..." : "")
                          : "Classes"}
                        Schedule
                      </Link>
                    </li>
                  </>
                }
                {maindata && maindata.showService === "yes" &&
                  <li>
                    <Link
                      onMouseEnter={handleMouseEnter}
                      onMouseLeave={handleMouseLeave}
                      to="Services" smooth={true} duration={500}>
                      {maindata?.service_title
                        ? maindata?.service_title.substring(0, 20) + (maindata?.service_title > 20 ? "..." : "")
                        : "Services"}
                    </Link>
                  </li>
                }
                {maindata && maindata.showPass === "yes" &&
                  <li>
                    <Link
                      onMouseEnter={handleMouseEnter}
                      onMouseLeave={handleMouseLeave}
                      to="Passes" smooth={true} duration={500}>Passes</Link>
                  </li>
                }

                {maindata && maindata.showWorkshop === "yes" &&
                  <>
                    <li>
                      <Link
                        onMouseEnter={handleMouseEnter}
                        onMouseLeave={handleMouseLeave}
                        to="Workshops" smooth={true} duration={500}>
                        {maindata?.workshop_title
                          ? maindata?.workshop_title.substring(0, 20) + (maindata?.workshop_title > 20 ? "..." : "")
                          : "Workshops"}
                      </Link>
                    </li>
                    <li>
                      <Link
                        onMouseEnter={handleMouseEnter}
                        onMouseLeave={handleMouseLeave}
                        to="Workshops_Schedule" smooth={true} duration={500}>    {maindata?.workshop_title
                          ? maindata?.workshop_title.substring(0, 20) + " " + (maindata?.workshop_title > 20 ? "..." : "")
                          : "Workshops"}  Schedule</Link>
                    </li>
                  </>
                }
                {maindata && maindata.showProduct === "yes" &&
                  <li>
                    <Link
                      onMouseEnter={handleMouseEnter}
                      onMouseLeave={handleMouseLeave}
                      to="Products" smooth={true} duration={500}>Products</Link>
                  </li>
                }
                <li>
                  <Link
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                    to="Testimonials" smooth={true} duration={500}>Testimonials</Link>
                </li>
                {webpreview && webpreview?.showOtherSection2 === "yes" &&
                  <li>
                    <Link
                      onMouseEnter={handleMouseEnter}
                      onMouseLeave={handleMouseLeave}
                      to="gallery2" smooth={true} duration={500}>
                      {webpreview?.other_title_2
                        ? webpreview?.other_title_2.substring(0, 20) + (webpreview?.other_title_2 > 20 ? "..." : "")
                        : ""}
                    </Link>
                  </li>
                }
                <li>
                  <Link
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                    to="WarriorMobileApp" smooth={true} duration={500}>Warrior Mobile App </Link>
                </li>
                <li>
                  <Link
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                    to="contact-us" smooth={true} duration={500}>Contact Details</Link>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </aside >
    </>
  )
}
