import React, { useState } from "react";

const VideoWithLoader = ({ src, type }) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);

  const handleCanPlay = () => setLoading(false);
  const handleError = () => {
    setLoading(false);
    setError(true);
  };

  const togglePlayPause = (videoRef) => {
    if (videoRef.paused) {
      videoRef.play();
      setIsPlaying(true);
    } else {
      videoRef.pause();
      setIsPlaying(false);
    }
  };

  return (
    <div style={styles.videoContainer}>
      {loading && !error && <div style={styles.loader}></div>}
      {error ? (
        <div style={styles.errorText}>Failed to load video</div>
      ) : (
        <>
          <video
            style={{ ...styles.video, display: loading ? "none" : "block" }}
            controls
            onCanPlay={handleCanPlay}
            onError={handleError}
            onPlay={() => setIsPlaying(true)}
            onPause={() => setIsPlaying(false)}
            id="video"
          >
            <source src={src} type={type} />
            Your browser does not support the video tag.
          </video>
          {!isPlaying && !loading && (
            <div
              style={styles.playIcon}
              onClick={() => togglePlayPause(document.getElementById("video"))}
            >
              ▶
            </div>
          )}
        </>
      )}
    </div>
  );
};

const styles = {
  videoContainer: {
    position: "relative",
    height: "250px",
    background: "#000",
  },
  video: {
    width: "100%",
    height: "100%",
  },
  loader: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    border: "8px solid #f3f3f3",
    borderTop: "8px solid #3498db",
    borderRadius: "50%",
    width: "50px",
    height: "50px",
    animation: "spin 1s linear infinite",
  },
  playIcon: { 
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    fontSize: "20px",
    color: "white",
    background: "rgba(14, 146, 234, 0.5)",
    borderRadius: "50%",
    padding: "18px 23px",
    cursor: "pointer",
    zIndex: 2,
  },
  errorText: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    color: "white",
    fontSize: "18px",
    textAlign: "center",
  },
};

export default VideoWithLoader;
