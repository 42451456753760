import React, { useEffect, useState } from 'react'
import OwlCarousel from 'react-owl-carousel';
import Loginmodals from '../../Mymodals/Loginmodals';
import CommanImagePopup from '../../Mymodals/CommanImagePopup';
import ImagePopup from '../../Mymodals/ImagePopup';
import { toCamelCase } from '../../Helper';


export default function TrainersNew({ data, webpreview }) {
    const [isDataLoaded, setIsDataLoaded] = useState(false);
    useEffect(() => {
        if (data && data.length > 0) {
            setIsDataLoaded(true);
        }
    }, [data]);


    const [isopen, setIsopen] = useState(false);
    const isClose = () => { setIsopen(false); }
    const [img, setImg] = useState();

    const imgPopupHndler = (data) => {    
        const final = {
            "image": data.profile_img || "img/trainers/1.jpg",
            "name": data.name +" "+ data.lastname,
            "description": data.about
        }
        setImg(final); setIsopen(true);
    }

    
    
    return (
        <>
            {/* Services */}
            <section id="Instructors">
                <div className="container">
                    <div className="row">
                        <div className="col-xs-12">
                            <div className="section-title text-center">                        
                                <h2 style={{color:`${webpreview && webpreview.Header_Fonts ? webpreview.Header_Fonts :""}`}}>{webpreview && webpreview.inst_header ? webpreview.inst_header : "Instructors"}</h2>
                                <small className='myline'></small>
                                <p>{webpreview && webpreview.inst_description ? webpreview.inst_description : "Invitamus me testatur sed"}</p>
                            </div>
                        </div>
                    </div>

                    <div className="row" style={{justifyContent:'center'}}>
                        {data && data.length > 0 && data.map((e, i) => (                         
                            <div className="col-xs-3 col-sm-3 col-md-3" key={i} style={{ cursor: 'pointer' }} onClick={() => imgPopupHndler(e)}>
                                <div className="course-list wow fadeInUp">
                                    <div className="course-thumb">
                                        <img src={e.profile_img ? e.profile_img : "img/courses/1.jpg"} alt="" />
                                    </div>
                                    <div className="course-content" style={{textAlign:'center'}}>
                                    <h3>{e.name+' ' } {e.lastname}</h3>                                   
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </section>
            <CommanImagePopup isopen={isopen} isClose={isClose} data={img} type="Log into Warrior to book a class or service:"/>
        </>
    )
}
