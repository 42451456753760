import React, { useContext, useEffect, useState } from 'react'
import Sidebar from '../Commanpage/Sidebar'
import Footer from '../Commanpage/Footer'
import AboutUs from './Pages/AboutUs'
import Services from './Pages/MyServices'
import Courses from './Pages/Courses'
import Pricing from './Pages/Pricing'
import Products from './Pages/Products'
import Testimonials from './Pages/Testimonials'
import ContactUs from './Pages/ContactUs'
import Workshoplist from './Pages/Workshoplist'
import { MyContext } from '../App'
import { getInstructor, getProducts, passes_list, service_list_with_image, webpreviewInfoByHostName,  workshoplistdata } from '../Services/Services'
import { useNavigate } from 'react-router-dom'
import { Course } from './Pages/Course'
import { Spinner } from 'react-bootstrap'
import Myworkshopslist from './Pages/Myworkshopslist'
import { Helmet } from 'react-helmet';
import TrainersNew from './Pages/TrainersNew'
import ControlledCarouselNew from './Pages/ControlledCarouselNew'
import Gallery from './Pages/Gallery'


export default function Home() {
    const { maindata, MaindataHnadler, fullInfoHandler } = useContext(MyContext);
    const [loder, setLoder] = useState(false);

    const [prddata, setPrddata] = useState([]);
    const [intrdata, setIntrdata] = useState([]);
    const [passdata, setPassdata] = useState([]);
    const [mainloder, setMainloder] = useState(true);
    const navigate = useNavigate();
    const [tetdata, setTetdata] = useState([]);
    const [webpreview, setWebpreview] = useState();

    const [other1, setOther1] = useState([]);
    const [other2, setOther2] = useState([]);



    const getInfoByHostName = async (hostName) => {
        localStorage.clear();
        const fdata = { "hostname": hostName};
        const resp = await webpreviewInfoByHostName(fdata);
        if (resp && resp.status === 1) {
            setMainloder(false);
            const businessId = resp.data.business_id;
            const data = resp.data;

            const other = data?.others || [];           

            const oth1 = other?.filter((e) => e?.section_type === "section_1");
            setOther1(oth1);
            const oth2 = other?.filter((e) => e?.section_type === "section_2");
            setOther2(oth2);


            setTetdata(data.testimonial);
            fullInfoHandler(data);
            setWebpreview(data);
            document.body.style.fontFamily = data.font_family;

            const table = document.querySelector('table');
            if (table) {
                const thElements = table.querySelectorAll('th');
                thElements.forEach(th => {
                    th.style.fontFamily = data.font_family;
                });
            }

            let slide = [];
            if (data) {
                slide.push({ 'brImage': data.brImage1, "brTitle": data.brTitle1, "brSubtitle": data.brSubtitle1, "showbnlogo": data.showbnlogo1  });
                slide.push({ 'brImage': data.brImage2, "brTitle": data.brTitle2, "brSubtitle": data.brSubtitle2, "showbnlogo": data.showbnlogo2 });
            }
            let final = { ...data };
            final['about'] = { 'abimage': data.abimage, "abtitle": data.abtitle, "abstory": data.abstory };
            final['slider'] = slide;
            localStorage.setItem('w_detailBack', '/my-home');
            localStorage.setItem("bzid", businessId);
            MaindataHnadler(final, businessId);
        } else {
            setMainloder(false);
            navigate("/comming-soon");
        }
        setMainloder(false);
    }

    useEffect(() => {
        if (window.location && window.location.hostname) {
            const hostName = window.location.hostname;
            console.log("---HostName----",hostName);
            if (hostName) {
                getInfoByHostName(hostName);
            }
        } else return;
    }, [])


    const prdata = async (id) => {
        const final = { "bzid": id };
        const resp = await getProducts(final);
        if (resp && resp.status === 1) {
            const data = resp.data;
            setPrddata(data);
        }
    }

    const intdata = async () => {
        const resp = await getInstructor({ "pageid": 1 });
        if (resp && resp.status === 1) {
            const data = resp.data;
            const filterdata = data.filter((e) => e.instructor_status === "Active");
            setIntrdata(filterdata);
        } else {
            setIntrdata([]);
        }
    }

    const passlistHandler = async () => {
        const resp = await passes_list({ "page_no": "1" });
        if (resp && resp.status === 1) {
            const data = resp.data;
            const filterData = [];
            let data1 = data.filter((e) => e.is_client_visible === "yes");

            let timefram = data1.filter((e) => e.pass_type === "Time Frame");
            let punch_card = data1.filter((e) => e.pass_type === "Punch Card");
            let recurring_membership = data1.filter((e) => e.pass_type === "Recurring Membership");

            timefram.forEach(element => { filterData.push(element); });
            recurring_membership.forEach(element => { filterData.push(element); });
            punch_card.forEach(element => { filterData.push(element); });

            setPassdata(filterData);
        } else {
            setPassdata([]);
        }
    }

    const [serviceData, setServiceData] = useState([]);
    const [myworkshopData, setMyworkshopData] = useState([]);

    const getServiceData = async () => {
        const resp = await service_list_with_image({ "pageid": 1 });
        if (resp.data.status == 1) {
            const data = resp.data.data;
            setServiceData(data);
        } else {
            setServiceData([]);
        }
    }

    const getWorkshoplistData = async () => {
        const resp = await workshoplistdata({ "pageid": 1 });
        if (resp.data.status == 1) {
            const data = resp.data.data;
            setMyworkshopData(data);
        } else {
            setMyworkshopData([]);
        }
    }
    useEffect(() => {
        if (maindata && maindata.bzid) {
            prdata(maindata.bzid);
            intdata();
            getServiceData();
            getWorkshoplistData();
            passlistHandler();
        }
    }, [maindata]);


    useEffect(() => {
        if (webpreview) {
            let link = document.querySelector("link[rel~='icon']");
            if (!link) {
                link = document.createElement('link');
                link.rel = 'icon';
                document.getElementsByTagName('head')[0].appendChild(link);
            }
            link.href = webpreview && webpreview.favicon ? webpreview.favicon : "";
        }
    }, [webpreview]);


    return (
        <>
            {loder && <div className='MiLoader'> <Spinner animation="border" role="status" className='Loader'> <span className="visually-hidden">Loading...</span>  </Spinner> </div>}
            {mainloder &&
                <div className='MainLoader'>
                    <div className=" spinner-grow text-danger" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
            }
            <Sidebar maindata={maindata}  webpreview={webpreview} />
            <div className="content-wrapper" >
                <Helmet>
                    <title>{webpreview && webpreview.business_name}</title>
                </Helmet>
                <ControlledCarouselNew data={maindata && maindata.slider} maindata={maindata} setLoder={setLoder} />
                <AboutUs data={maindata && maindata.about} webpreview={webpreview} setLoder={setLoder} />

                {webpreview && webpreview.showOtherSection1 === "yes" &&  <Gallery data={other1} webpreview={webpreview} position={1} />}

                {maindata && maindata.showInstructor === "yes" && <TrainersNew data={intrdata} webpreview={webpreview} setLoder={setLoder} />}
                {maindata && maindata.showClass === "yes" && <Course webpreview={webpreview} maindata={maindata} setLoder={setLoder} />}
                {maindata && maindata.showClass === "yes" && <Courses webpreview={webpreview} maindata={maindata} setLoder={setLoder} />}              
                {maindata && maindata.showService === "yes" && <Services data={serviceData} maindata={maindata} webpreview={webpreview} setLoder={setLoder} />}
                {maindata && maindata.showPass === "yes" && <Pricing data={passdata} webpreview={webpreview} setLoder={setLoder} />}
                {maindata && maindata.showWorkshop === "yes" && <Myworkshopslist data={myworkshopData} maindata={maindata} webpreview={webpreview} setLoder={setLoder} />}
                {maindata && maindata.showWorkshop === "yes" && <Workshoplist webpreview={webpreview} maindata={maindata} setLoder={setLoder} />}
                {maindata && maindata.showProduct === "yes" && <Products data={prddata} webpreview={webpreview} setLoder={setLoder} />}
                {tetdata && tetdata.length > 0 && <Testimonials data={tetdata} webpreview={webpreview} setLoder={setLoder} />}

                {webpreview && webpreview.showOtherSection2 === "yes" && <Gallery data={other2} webpreview={webpreview} position={2} />}

                <ContactUs data={maindata} setLoder={setLoder} webpreview={webpreview} />
                <Footer webpreview={webpreview} />
            </div>
        </>
    )
}
